import React, { useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import ReactDOM from 'react-dom';

function ReqCur(props) {
	const [state, setState] = useState({
		hey: 'Me',
		color: 'black',
		title: "How can we help you?",
		buttonDisabled: false
	});

	const [showModal, setShowModal]=useState(false)

	const handleSubmit = (event) => {
		setState(prevState => ({ ...prevState, buttonDisabled: true }));
		event.preventDefault();
		const nameInput = event.target.elements.name;
		const emailInput = event.target.elements.email;

		setState(prevState => ({ ...prevState, color: 'black' }));
		const data = { 
			email: emailInput.value, 
			name: nameInput.value, 
			course: props.course, 
			link: props.link, 
			courselink: props.courselink
		};
		setShowModal(true)
		axios.post('/reqCur', data)
		.then((response) => {
			emailInput.value = "";
			nameInput.value = "";
                // alert("Your email is on the way!");
			setState(prevState => ({ ...prevState, buttonDisabled: false }));
		})
		.catch((error) => {
			setState(prevState => ({ ...prevState, buttonDisabled: false }));
			console.log(error);
		});
		console.log("--SeNd!--");
	};

	return (
		<div className="">
		<form onSubmit={handleSubmit} className="fluidFlex">
		<input
		className="reqCurInput"
		required={true}
		style={{
			width: '100%',
			border: '1px solid grey',
			marginBottom: '1em',
			flex: '1 1 200px'
		}}
		type="text"
		placeholder="What's your name?"
		name="name"
		/>
		<input
		className="reqCurInput"
		required={true}
		style={{
			width: '100%',
			border: '1px solid grey',
			marginBottom: '1em',
			flex: '1 1 200px'
		}}
		type="email"
		placeholder="What's your email?"
		name="email"
		/>
		<button
		style={{
			width: '100%',
			border: '1px solid grey',
			marginBottom: '1em',
			flex: '1 1 200px'
		}}
		type="submit"
		disabled={state.buttonDisabled}
		>
		Get it!
		</button>
		</form>
		{showModal&&<div className='transparentContainer' style={styles.modal}>
		<section className='whiteCard' style={styles.modalInner}>	
		<span style={styles.closeCross} onClick={()=>setShowModal(false)}>X</span>
		<h1 style={styles.modalInnerText}>Thank you! <br/>Your email is on the way...</h1>
		</section>
		</div>}
		</div>
		);
}

export default ReqCur;

const styles={
	modal:{
		position:'fixed',
		top:'50%',
		left:'50%',
		transform:'translate(-50%,-50%)',
		backgroundColor:'white',
		width:'100%',
		height:'100%',
		// padding:'50px',
		zIndex:1000
	},
	modalInner:{
		// padding:'50px',
		textAlign:'center',
		display:'flex',
		flexDirection:'column',
		height:'100%',
		alignItems:'center',
		justifyContent:'center',
	},
	modalInnerText: {
		// fontSize:'5vw'
		// alignSelf:'center',
	},
	closeCross:{
		position:'absolute',
		top:'0',
		right:'0',
		padding:'30px',
		cursor:'pointer',
		fontSize:'1.5em'
	}
}

